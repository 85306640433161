<template>
 
    <div class="iso-products">
 
        <div class="products-header-container">
            <p class="products-header">Панели</p>
 
        </div>
 
        <div class="iso-products-container">
 
            <div class="iso-product" v-for="(product, index) in visibleProducts" :key="product.productName">
                <img :src="product.imgPath" class="product-image">
                <p class="product-name">{{ product.productName }}</p>
                <p class="product-desc">{{ product.desctiption[0]}}</p>
                <button @click="showProductDetails(product)" class="view-more-btn">ВИЖ ПОВЕЧЕ</button>
 
            </div>
 
            <button v-if="showLoadMoreButton" class="load-more-btn" @click="loadMore">ЗАРЕДИ ОЩЕ...</button>
 
 
 
        </div>
 
        <div v-if="selectedProduct" class="product-details">
            <button @click="closeProductDetails" class="close-btn">&#x2715;</button>
            <img :src="selectedProduct.imgPath" class="product-more-image">
            <p class="product-more-name">{{ selectedProduct.productName }}</p>
 
            <div class="product-more-sec">
                <div class="product-details-section">
                    <p class="section-title">Описание:</p>
                    <div v-for="desc in selectedProduct.desctiption" :key="desc" class="product-more-desc">{{ desc }}</div>
                </div>
                 <div class="product-details-section">
                    <p class="section-title">Спецификации:</p>
                    <div v-for="spec in selectedProduct.specs" :key="spec" class="list-item"><span class="bullet">&#x2023; </span>{{ spec }}</div>
                </div>
            </div>
 
        </div>
 
    </div>
 
</template>
 
<script>
    import image1Path from '../../utils/IsoProducts/fortelia1.jpg';
    import image2Path from '../../utils/IsoProducts/greenroof.jpg';
    import image3Path from '../../utils/IsoProducts/isofrozen.jpg';
    import image4Path from '../../utils/IsoProducts/isodeckpvsteel.jpg';
    import image5Path from '../../utils/IsoProducts/isopareteevo.jpg';
    import image6Path from '../../utils/IsoProducts/isobox.jpg';
    import image7Path from '../../utils/IsoProducts/isocop.jpg';
    import image8Path from '../../utils/IsoProducts/isocopmulti.jpg';
    import image9Path from '../../utils/IsoProducts/isoboxplisse.jpg';
    import image10Path from '../../utils/IsoProducts/isocopfarmcoat.jpg';
    import image11Path from '../../utils/IsoProducts/isocoptopclass.jpg';
    import image12Path from '../../utils/IsoProducts/isopareteplus2.jpg';
    import image13Path from '../../utils/IsoProducts/isopareteplisse.jpg';
    import image14Path from '../../utils/IsoProducts/isoparetepiano.jpg';
    import image15Path from '../../utils/IsoProducts/isopiano.jpg';
    import image16Path from '../../utils/IsoProducts/isoparetebox.jpg';
    import image17Path from '../../utils/IsoProducts/isodecksynth.jpg';
    import image18Path from '../../utils/IsoProducts/isodeck.jpg';
    import image19Path from '../../utils/IsoProducts/isofirewall.jpg';
    import image20Path from '../../utils/IsoProducts/isofirewallfg.jpg';
    import image21Path from '../../utils/IsoProducts/isofirerooffg.jpg';
    import image22Path from '../../utils/IsoProducts/isofirewallplisse.jpg';
    import image23Path from '../../utils/IsoProducts/isofirewallfono.jpg';
    import image24Path from '../../utils/IsoProducts/isofirewallvf.jpg';
    import image25Path from '../../utils/IsoProducts/isofirewallhf.jpg';
    import image26Path from '../../utils/IsoProducts/isofirerooffgg.jpg';
    import image27Path from '../../utils/IsoProducts/isofirerooffono.jpg';
    import image28Path from '../../utils/IsoProducts/isofireroof.jpg';
    import image29Path from '../../utils/IsoProducts/isodomusclassic.jpg';
    import image30Path from '../../utils/IsoProducts/isodomus.jpg';
    import image31Path from '../../utils/IsoProducts/lg40.jpg';
    import image32Path from '../../utils/IsoProducts/isovela.jpg';
    import image33Path from '../../utils/IsoProducts/isotap.jpg';
    import image34Path from '../../utils/IsoProducts/lg153.jpg';
    import image35Path from '../../utils/IsoProducts/LG55.jpg';
    import image36Path from '../../utils/IsoProducts/lg50.jpg';
    import image37Path from '../../utils/IsoProducts/lg28.jpg';
    import image38Path from '../../utils/IsoProducts/lg20.jpg';
    import image39Path from '../../utils/IsoProducts/isovetro.jpg';
    import image40Path from '../../utils/IsoProducts/isomart.jpg';
    import image41Path from '../../utils/IsoProducts/isorighe.jpg';
    import image42Path from '../../utils/IsoProducts/isoray.jpg';
    import image43Path from '../../utils/IsoProducts/isogrecata.jpg';
    import image44Path from '../../utils/IsoProducts/isoclass.jpg';
 
 
    export default {
    name: 'products',
    data() {
        return {
        products: [
            { productName: 'FORTELIA', imgPath: image1Path, 
                desctiption: [
                "Fortelia представлява истинска иновация в областта на сглобяемите строителни решения: гъвкавост, устойчивост и гъвкавост, невероятно по-големи от повечето сравними продукти, открити на пазара. Fortelia предлага обширни решения за различни видове приложения, характеризиращи се с голямо разнообразие от конфигурации.",
                "Валиден и съвременен архитектурен избор, Fortelia предлага здравина, висока производителност по отношение на топлоизолация и устойчивост на натоварвания, които могат да бъдат постигнати с по-тънки панели. В допълнение, в резултат на геометричното напречно сечение, той може да изпълни изискванията за размери за облицоване на големи структурни празнини."
                ], 
                specs: ["Няма допълнителни спецификации"],
                turn: 'one'
            },
            { productName: 'GREEN ROOF', imgPath: image2Path, 
                desctiption: [
                "Green Roof е сложна система, съставена от материали, които работят един с друг в хармония и синергия. Приспособявайки се към плоски покриви, GreenROOF се основава на филтриращ геотекстилен елемент и на дренажни компоненти, които влизат в контакт с панела Isodeck PVSteel.",
                "Пропускливост, задържане на вода, производство на кислород, ниво на плодовитост, гарантирана способност за изхвърляне на излишната вода в рамките на определен период от време, са само някои от параметрите, които трябва да се имат предвид при оценката на качеството на покривна зелена система и Green Roof отговаря на всички от тях в съответствие със стандарта UNI 11235."
                ], 
                specs: ["Няма допълнителни спецификации"],  
                turn: "one"           
            },
            { productName: 'ISOFROZEN', imgPath: image3Path, 
                desctiption: [
                "Стенният панел Isofrozen е подходящ за използване в промишлени сгради, хладилни складове, производствени съоръжения и вътрешни отделения.",
                "Isofrozen е стенен сандвич панел, използван за инсталации на хладилни клетки, среди с контролирана температура и отделения. Съединението е с лабиринтово съединение и закрепване на виждане. Има възможност за избор на вида на кръстовището според изискваните изисквания за топлоизолация и въздухонепроницаемост.",
                ], 
                specs: [
                "Панел с двойна облицовка от метал с микро-оребрено покритие",
                "Полиуретанова изолация",
                "Възможност за избор на вид фуга според необходимата топлоизолация и въздухонепропускливост",
                "Фуга с видимо фиксиране",
                "Може да се монтира вертикално или хоризонтално"
                ],  
                turn: 'one'           
            },
            { productName: 'ISODECK PVSTEEL', imgPath: image4Path, 
                desctiption: [
                "Isodeck pvsteel (isopan rus) полиуретанов сандвич панел с оребрен профил, предназначен за плоски покриви. Вътрешната страна се състои от лист, облицован с PVC фолио.",
                "Isodeck PVSteel е сандвич панел за плоски покриви с наличие на двоен лист, което го прави устойчив на натоварвания.",
                ], 
                specs: [
                "Панел с двойна метална обшивка",
                "Полиуретанова изолация",
                "Плосък лист на софита, покрит с PVC фолио",
                "Фуга с видимо фиксиране",
                "5-ребрен оребрен профил"
                ],       
                turn: 'one'      
            },
            { productName: 'ISOPARETE EVO', imgPath: image5Path, 
                desctiption: [
                "Полиуретанов стенен панел Isoparete EVO, с микропрофилно покритие и скрито закрепване.",
                "Isoparete EVO е облицовка от сандвич панели, използвана за стени на промишлени и търговски сгради, характеризираща се със скрита система за закрепване на фуги. Можете да изберете вида на външното покритие от 3 различни профила (погледнете снимките отгоре надолу: Box, Plissè, Double).",
                ], 
                specs: [
                "Панел с двойна метална обшивка",
                "Полиуретанова изолация",
                "Фуга със скрито фиксиране",
                "Възможност за избор на профил от външната страна",
                ],   
                turn: 'one'          
            },
            { productName: 'ISOBOX', imgPath: image6Path, 
                desctiption: [
                "Изобокс полиуретанов панел за стена, с микро-оребрено покритие и видимо фиксиране. Може да се монтира вертикално или хоризонтално",
                "Isobox е сандвич панел, използван за стени на промишлени съоръжения, модули и вътрешни прегради.",
                ], 
                specs: [
                "Панел с двойна облицовка от метал с микро-оребрено покритие",
                "Полиуретанова изолация",
                "Фуга с видимо фиксиране",
                "Може да се монтира вертикално или хоризонтално",
                ],   
                turn: 'one'          
            },
            { productName: 'ISOCOP', imgPath: image7Path, 
                desctiption: [
                "Сандвич панел Isocop за полиуретаново покритие, с 5-ребрен оребрен профил.",
                "Isocop е сандвич панел за покриви, идеален за промишлени, търговски и селскостопански и зоотехнически съоръжения. Гъвкавостта, устойчивостта на натоварване и лесният монтаж правят Isocop надеждно решение за всякакъв вид интервенция, от нова конструкция до ремонт на покрив.",
                ], 
                specs: [
                "Сандвич панел с двойна метална облицовка",
                "Полиуретанова изолация",
                "5-рамка гофриран профил",
                "Възможност за използване за стени",
                ],     
                turn: 'two'        
            },
            { productName: 'ISOCOP MULTIFUNCTION', imgPath: image8Path, 
                desctiption: [
                "Isocop 4-ребрен многофункционален сандвич панел за полиуретанов покрив, с оребрен профил и система за интегриране на аксесоари.",
                "В допълнение към полиуретанова изолация, Isocop 4-ребрен мултифункционален панел има специално профилиране на външния метален лист, което позволява бърз и лесен монтаж на специални скоби на покрива. По този начин можете да инсталирате слънчеви панели на вашия покрив.",
                ], 
                specs: [
                "Сандвич панел с двойна метална облицовка",
                "Полиуретанова изолация",
                "4-ребрен оребрен профил",
                "Патентована система за фиксиране на правилните скоби върху външния лист",
                ],   
                turn: 'two'          
            },
            { productName: 'ISOBOX PLISSÈ', imgPath: image9Path, 
                desctiption: [
                "Полиуретанов полиуретанов стенен панел Isobox, с микродиамантено покритие и видимо фиксиране. Може да се монтира вертикално или хоризонтално",
                "Isobox Plissè е сандвич панел, използван за стени на промишлени съоръжения, модули и вътрешни прегради.",
                ], 
                specs: [
                "Панел с двойно метално покритие и микроламели",
                "Полиуретанова изолация",
                "Фуга с видимо фиксиране",
                "Може да се монтира вертикално или хоризонтално",
                ],   
                turn: 'two'           
            },
            { productName: 'ISOCOP FARM COAT', imgPath: image10Path, 
                desctiption: [
                "Покриващ панел, характеризиращ се с използването на специален вътрешен предварително боядисан лист, особено устойчив на действието на агресивни агенти, присъстващи в агро-животновъдни среди.",
                "Isocop Farm Coat е полиуретанов изолационен панел с външен 5-ребрен профилиран лист. При големи надлъжни стъпки може да се осигури припокриване на припокриването между панелите. Вътрешната страна се характеризира със специален предварително боядисан лист, устойчив на действията на агресивни агенти в животновъдната среда. Закрепванията са проходни с възможност за използване на видими капачки.",
                ], 
                specs: [
                "Единичен листов панел",
                "Полиуретанова изолация",
                "5-ребрен оребрен профил",
                ], 
                turn: 'two'             
            },
            { productName: 'ISOCOP TOPCLASS', imgPath: image11Path, 
                desctiption: [
                "Покриващ панел, характеризиращ се с използването на специална вътрешна поцинкована ламарина с PVC покритие, проектирана да устои на действието на агресивни агенти, присъстващи в агро-зоотехническа среда.",
                "Isocop Topclass е изолационен панел от полиуретан, с 5-ребрена профилна външна ламарина. При големи надлъжни стъпки може да се осигури припокриване на припокриването между панелите. Вътрешната страна се характеризира със специална поцинкована ламарина, покрита с PVC, устойчива на въздействието на агресивни агенти в зоотехническа среда.",
                ], 
                specs: [
                "Вълнообразно профилиране",
                "Полиуретанова изолация",
                "Съединение с видими фиксатори",
                "Вътрешна поцинкована ламарина",
                ],  
                turn: 'two'            
            },
            { productName: 'ISOPARETE PLUS 2', imgPath: image12Path, 
                desctiption: [
                "Полиуретанов стенен панел Isoparete plus 2, с микропрофилно покритие и скрито закрепване. Стенният сандвич панел Isoparete plus предлага възможност за избор на профил от външната страна.",
                "Isoparete PLUS 2 е сандвич панел, използван за стени на промишлени и търговски сгради, характеризиращ се със скрита система за закрепване на фуги. Можете да изберете вида на външното покритие от 3 различни профила.",
                ], 
                specs: [
                "Панел с двойно метално покритие",
                "Полиуретанова изолация",
                "Фуга със скрито фиксиране",
                "Възможност за избор на профил от външната страна",
                ],  
                turn: 'two'            
            },
            { productName: 'ISOPARETE PLISSÉ', imgPath: image13Path, 
                desctiption: [
                "Полиуретанов стенен панел Isoparete Plissé, с микропрофилно покритие и скрито фиксиране. Стенният сандвич панел Isoparete Plissé може да се комбинира със съвместими панели.",
                "Isoparete Plissé е сандвич панел, използван за стени на промишлени и търговски сгради, характеризиращ се със скрита система за закрепване на фуги. Може да се използва в комбинация със стенните сандвич панели Isoparete Piano и Isoparete Box.",
                ], 
                specs: [
                "Панел с двойна облицовка от метал с микро-оребрено покритие",
                "Полиуретанова изолация",
                "Фуга със скрито фиксиране",
                "Възможност за комбиниране със съвместими панели",
                ],    
                turn: 'three'          
            },
            { productName: 'ISOPARETE PIANO', imgPath: image14Path, 
                desctiption: [
                "Полиуретанов стенен панел Isoparete Piano, с гладко покритие и скрито фиксиране. Стенният сандвич панел Isoparete Piano може да се комбинира със съвместими панели.",
                "Isoparete Piano е сандвич панел, използван за стени на промишлени и търговски сгради, характеризиращ се със скрита система за закрепване на фуги. Може да се използва в комбинация със стенни сандвич панели Isoparete Plissè и Isoparete Box.",
                ], 
                specs: [
                "Панел с двойно метално покритие",
                "Полиуретанова изолация",
                "Фуга със скрито фиксиране",
                "Възможност за комбиниране със съвместими панели",
                ],     
                turn: 'three'         
            },
            { productName: 'ISOPIANO', imgPath: image15Path, 
                desctiption: [
                "Стенен полиуретанов панел Isopiano, с гладко покритие и видимо фиксиране. Може да се монтира вертикално или хоризонтално.",
                "Isopiano е сандвич панел, използван за стени на промишлени съоръжения, модули и вътрешни прегради.",
                ], 
                specs: [
                "Панел с двойна метална обшивка",
                "Полиуретанова изолация",
                "Съединение с видимо фиксиране",
                "Може да се монтира вертикално или хоризонтално",
                ],      
                turn: 'three'        
            },
            { productName: 'ISOPARETE BOX', imgPath: image16Path, 
                desctiption: [
                "Това е стенен панел от двоен стоманен лист, изолиран с полиизоциануратна твърда пяна. Сглобяването на език и горичка се извършва с помощта на скрит сквозно закрепен винт и скоба за седло. Външно лице се предлага в профили plissé, box и piano. вътрешно лице, налично в профил на кутия."
                ], 
                specs: ["Няма допълнителни спецификации"],      
                turn: 'three'        
            },
            { productName: 'ISODECK SYNTH', imgPath: image17Path, 
                desctiption: [
                "Isodeck синтетичен полиуретанов сандвич панел с 5-ребрен оребрен профил, предназначен за плоски покриви. Вътрешната страна се състои от синтетична мембрана.",
                "Isodeck Synth е панел за плоски покриви, с ребра от долната страна. Специална синтетична мембрана се монтира на софита и позволява направата на плоски покриви и леко наклонени покриви на промишлени сгради.",
                ], 
                specs: [
                "Единичен панел",
                "Полиуретанова изолация",
                "Опора на софита, състояща се от синтетична мембрана",
                "5-ребрен оребрен профил",
                ],    
                turn: 'three'          
            },
            { productName: 'ISODECK', imgPath: image18Path, 
                desctiption: [
                "Isodeck сандвич панел за полиуретаново покритие, с 5-ребрен оребрен профил и опора от битумен филц.",
                "Isodeck е еднослоен панел, предназначен за промишлени сгради, навеси, сглобяеми модули и навеси. Плоската страна, оформена от лист битуминозен филц, дава възможност за монтиране на Isodeck с оребрения метален профил, обърнат навътре, така че да се получи плосък или леко наклонен покрив.",
                ], 
                specs: [
                "Единичен панел",
                "Полиуретанова изолация",
                "5-ребрен оребрен профил",
                "Вътрешна страна от битумен филц",
                "Използва се с оребрения профил към софита"
                ],     
                turn: 'three'         
            },
            { productName: 'ISOFIRE WALL', imgPath: image19Path, 
                desctiption: [
                "Isofire стенен панел от минерална вата, с двойно метално покритие. Микро-набраздено покритие и видимо фиксиране.",
                "Isofire Wall е сандвич панел, използван за стени на промишлени съоръжения. Има сглобка на език и жлеб и видимо фиксиране. Изолацията от каменна вата го прави огнеустойчив.",
                ], 
                specs: [
                "Панел с двойно метално покритие",
                "Изолация от каменна вата",
                "Фуга с видимо фиксиране",
                "Може да се монтира вертикално или хоризонтално",
                ],   
                turn: 'four'           
            },
            { productName: 'ISOFIRE WALL FG-VF FONO', imgPath: image20Path, 
                desctiption: [
                "Двустенен стенен панел с изолация от стъклена минерална вата.",
                "Съединението с мъжко-женско съединение е от видим тип с преминаващ винт. Има вътрешна опора, състояща се от микроперфориран лист, способен да увеличи звукопоглъщащата производителност на панела.",
                ], 
                specs: [
                "Панел с двойна облицовка от метал с микро-оребрено покритие",
                "Изолация от каменна вата",
                "Фуга с видимо фиксиране",
                "Вътрешна страна от фибростъкло",
                ],    
                turn: 'four'          
            },
            { productName: 'ISOFIRE ROOF FG FONO', imgPath: image21Path, 
                desctiption: [
                "Сандвич панел с двойно метално покритие, за покриви с наклон не по-малък от 7%, изолиран от минерална стъклена вата, с външна ламарина 5 гръцки профили.",
                "Закрепването е видимо, със специални метални капачки с уплътнение. Той има вътрешна опора, състояща се от перфорирана ламарина, която може да увеличи звукопоглъщащата способност на панела.",
                ], 
                specs: [
                "Сандвич панел с двойна метална облицовка",
                "Изолация от каменна вата",
                "5-ребрен оребрен профил",
                ],     
                turn: 'four'        
            },
            { productName: 'ISOFIRE WALL PLISSÉ', imgPath: image22Path, 
                desctiption: [
                "Стенен панел Isofire от каменна вата, с покритие от микродиамант и скрито фиксиране.",
                "Isofire Wall Plissé е сандвич панел, използван за стени на промишлени и търговски сгради, характеризиращ се със скрита система за закрепване на фуги. Изолацията от каменна вата го прави огнеустойчив.",
                ], 
                specs: [
                "Панел с двойно метално покритие и микроръбове",
                "Полиуретанова изолация",
                "Фуга със скрито фиксиране",
                "Може да се монтира вертикално или хоризонтално",
                ],     
                turn: 'four'        
            },
            { productName: 'ISOFIRE WALL FONO', imgPath: image23Path, 
                desctiption: [
                "Стенен панел Isofire Wall Fono от минерална вата, с микроперфорирана вътрешна ламарина и видима система за закрепване.",
                "Това е самоносещ панел с метално покритие, изолиран с минерална вата. Фиксиращите елементи са открити. Вътрешният лист се характеризира с микропробиване, което подобрява акустичните характеристики; което означава звукопоглъщане и изолация.",
                ], 
                specs: [
                "Панел с двойно метално покритие",
                "Изолация от каменна вата",
                "Фуга с видимо фиксиране",
                "Вътрешен лист с микроотвори",
                "Възможност за хоризонтално или вертикално полагане"
                ],     
                turn: 'four'        
            },
            { productName: 'ISOFIRE WALL FG VF', imgPath: image24Path, 
                desctiption: [
                "Isofire Wall FG VF стенен панел от стъклена вата, с двойно метално покритие. Микро-набраздено покритие и видимо фиксиране.",
                "Isofire Wall FG VF е сандвич панел, използван за стени на промишлени съоръжения. Има сглобка на език и жлеб и видимо фиксиране. Изолацията от стъклена вата осигурява защита при пожар и в същото време по-голяма лекота от каменната вата. Освен това изолацията от стъклена вата е изключително устойчиво решение с високо съдържание на рециклирани материали (> 80%).",
                ], 
                specs: [
                "Панел с двойно метално покритие",
                "Изолация от стъклена вата",
                "Фуга с видимо фиксиране",
                "Може да се монтира вертикално или хоризонтално",
                ],   
                turn: 'four'          
            },
            { productName: 'ISOFIRE WALL FG HF', imgPath: image25Path, 
                desctiption: [
                "Isofire wall FG HF стенен панел от стъклена вата, с микродиамантено покритие и скрито фиксиране.",
                "Isofire Wall FG HF е сандвич панел, използван за стени на промишлени съоръжения. Има сглобка на перо и жлеб и скрито фиксиране. Изолацията от стъклена вата осигурява защита при пожар и в същото време по-голяма лекота от каменната вата. Освен това изолацията от стъклена вата е изключително устойчиво решение с високо съдържание на рециклирани материали (> 80%).",
                ], 
                specs: [
                "Панел с двойно метално покритие",
                "Изолация от стъклена вата",
                "Фуга със скрито фиксиране",
                "Може да се монтира вертикално или хоризонтално",
                ],      
                turn: 'five'       
            },
            { productName: 'ISOFIRE ROOF FG', imgPath: image26Path, 
                desctiption: [
                "Isofire Roof Fono сандвич панел за покриви от минерална вата, с 5-ребрен оребрен профил. Благодарение на вътрешния си лист с микроотвори, той е високоефективен по отношение на акустичните характеристики и звукопоглъщането.",
                "Isofire Roof Fono е сандвич панел за стени на промишлени сгради, производствени помещения, вътрешни прегради. Изолационният слой от каменна вата дава здравина и защита при пожар. Наличието на вътрешен лист с микро-отвори прави Isofire Roof Fono продукт, способен да задоволи изискванията за звукопоглъщане и акустични характеристики, както в случая на машинни отделения и производствени помещения.",
                ], 
                specs: [
                "Панел с двойна метална обшивка",
                "Изолация от каменна вата",
                "Съединение с видимо фиксиране",
                "Вътрешен лист с микроотвори",
                "5-ребрен оребрен профил"
                ],   
                turn: 'five'          
            },
            { productName: 'ISOFIRE ROOF FONO', imgPath: image27Path, 
                desctiption: [
                "Isofire Roof Fono сандвич панел за покриви от минерална вата, с 5-ребрен оребрен профил. Благодарение на вътрешния си лист с микроотвори, той е високоефективен по отношение на акустичните характеристики и звукопоглъщането.",
                "Isofire Roof Fono е сандвич панел за стени на промишлени сгради, производствени помещения, вътрешни прегради. Изолационният слой от каменна вата дава здравина и защита при пожар. Наличието на вътрешен лист с микро-отвори прави Isofire Roof Fono продукт, способен да задоволи изискванията за звукопоглъщане и акустични характеристики, както в случая на машинни отделения и производствени помещения.",
                ], 
                specs: [
                "Панел с двойна метална обшивка",
                "Изолация от каменна вата",
                "Съединение с видимо фиксиране",
                "Вътрешен лист с микроотвори",
                "5-ребрен оребрен профил"
                ],    
                turn: 'five'           
            },
            { productName: 'ISOFIRE ROOF', imgPath: image28Path, 
                desctiption: [
                "Покривен сандвич панел Isofire за покриви от минерална вата, с 5-ребрен оребрен профил.",
                "Isofire Roof е панел за покриви, подходящ за нови конструкции и обновяване на промишлени и търговски покриви. Изолацията от минерална вата осигурява здравина и защита при пожар.",
                ], 
                specs: [
                "Панел с двойна метална обшивка",
                "Изолация от каменна вата",
                "Съединение с видимо фиксиране",
                "5-ребрен оребрен профил",
                ],    
                turn: 'five'           
            },
            { productName: 'ISODOMUS CLASSIC', imgPath: image29Path, 
                desctiption: [
                "Сандвич панел Isodomus, специално проектиран за гражданско строителство. Структурата на външното лице идеално се интегрира с околната градска тъкан. Гама от панели, отговарящи на изискванията за покриви с ефект на керемиди.",
                "Isodomus е сандвич панел за покриви, предназначен за покриване на жилищни сгради или индустриални съоръжения, включени в градския контекст. Идеален за покриви с полиуретанова изолация в нови сгради, както и за реновиране или саниране..",
                ], 
                specs: [
                "Сандвич панел с двойна метална облицовка",
                "Полиуретанова изолация",
                "Покритие с ефект на плочка",                
                ],  
                turn: 'five'             
            },
            { productName: 'ISODOMUS', imgPath: image30Path, 
                desctiption: [
                "Сандвич панел Isodomus, специално проектиран за гражданско строителство. Структурата на външното лице идеално се интегрира с околната градска тъкан. Гама от панели, отговарящи на изискванията за покриви с ефект на керемиди.",
                "Isodomus е сандвич панел за покриви, предназначен за покриване на жилищни сгради или индустриални съоръжения, включени в градския контекст. Идеален за покриване на покриви с полиуретанова изолация в нови сгради, както и за реновиране или обновяване.",
                ], 
                specs: [
                "Сандвич панел с двойна метална облицовка",
                "Полиуретанова изолация",
                "Покритие с ефект на шисти",
                ],    
                turn: 'five'           
            },
            { productName: 'LG 40', imgPath: image31Path, 
                desctiption: [
                "Вълнообразна ламарина LG 40 подходяща за покриване на покриви или стени. Може да се направи с обърнат профил (гофриран лист 40r)",
                "LG 40 е гофриран лист за покриви, подходящ за промишлени и търговски сгради, наличен в стомана или алуминий и във версия с микроотвор.",
                ], 
                specs: [
                "Вълнообразен профил",
                "Подходящ за покриване на покриви или стени",
                "Стъпка 1000 мм",
                "Предлага се във версия с микроотвор",
                "Предлага се с обърнат профил (LG 40R)"
                ],    
                turn: 'six'           
            },
            { productName: 'ISOVELA & ISOVELA CLASSIC', imgPath: image32Path, 
                desctiption: [
                "Класически полиуретанови сандвич панели Isovela със синусоидален гофриран профил и двойна метална облицовка.",
                "Isovela Classic е сандвич панел за покриви, идеален за нови сгради или за реновиране на жилищни сгради или промишлени или зоотехнически съоръжения като алтернатива на класическите вълнообразни покриви.",
                ], 
                specs: [
                "Сандвич панел с двойна метална облицовка",
                "Полиуретанова изолация",
                "Вълнообразен профил",
                ],    
                turn: 'six'           
            },
            { productName: 'ISOTAP', imgPath: image33Path, 
                desctiption: [
                "Изотеп сандвич панел за полиуретаново покритие, с 3-ребрен оребрен профил и система за скрито закрепване.",
                "Isotap е сандвич панел с двойна метална облицовка, характеризиращ се със специална система за фиксиране, състояща се от скрита фуга. Оребреният профил му осигурява силна устойчивост на натоварване.",
                ], 
                specs: [
                "Сандвич панел с двойна метална облицовка",
                "Полиуретанова изолация",
                "Система със скрита фиксираща фуга",
                "3-ребрен оребрен профил",
                ],   
                turn: 'six'            
            },
            { productName: 'LG 153', imgPath: image34Path, 
                desctiption: [
                "Вълнообразна ламарина LG 153 подходяща за носещи подове или кофраж за еднократна употреба.",
                "LG 153 е гофриран лист за подове, подходящ за изпълнение на носещи подове на промишлени, жилищни и търговски сгради.",
                ], 
                specs: [
                "Вълнообразен профил",
                "Подходящ за носещи подове или кофраж за еднократна употреба",
                "Стъпка 840 мм",
                ],    
                turn: 'six'           
            },
            { productName: 'LG 55', imgPath: image35Path, 
                desctiption: [
                "Вълнообразна ламарина LG 55 подходяща за носещи подове или кофраж за еднократна употреба.",
                "LG 55 е гофриран лист за подове, подходящ за изпълнение на носещи подове на промишлени, жилищни и търговски сгради.",
                ], 
                specs: [
                "Вълнообразен профил",
                "Подходящ за носещи подове или кофраж за еднократна употреба",
                "Available with 600 mm or 750 mm pitch",
                ],     
                turn: 'six'          
            },
            { productName: 'LG 50', imgPath: image36Path, 
                desctiption: [
                "Вълнообразна ламарина LG 50 за покриви, специално проектирана за гражданско строителство. Структурата му е подходяща за покрив от листове с ефект на плочки, който е идеално интегриран в заобикалящата градска тъкан.",
                "LG 50 е гофриран лист за покриви на жилищни сгради или промишлени съоръжения, вмъкнати в градския контекст. Идеален за покриви на нови сгради и за реновиране или преустройство.",
                ], 
                specs: [
                "Профил с ефект на плочки",
                "Възможност за поставяне на антикондензен филц (по желание)",
                "Стъпка 1000 мм",
                ],
                turn: 'six'               
            },
            { productName: 'LG 28', imgPath: image37Path, 
                desctiption: [
                "Вълнообразна ламарина LG 28 подходяща за покриване или облицовка на стени.",
                "LG 28 е гофриран лист за покриви, подходящ за промишлени и търговски сгради, наличен в стомана или алуминий.",
                ], 
                specs: [
                "Вълнообразен профил",
                "Подходящ за покриване на покриви или стени",
                "Стандартна стъпка 896 мм",
                ],   
                turn: 'seven'            
            },
            { productName: 'LG 20', imgPath: image38Path, 
                desctiption: [
                "Вълнообразна ламарина LG 20 подходяща за покривни или стенни покрития. Подходящ е за покриви от алуминиева ламарина.",
                "LG 20 е гофриран лист за покриви, подходящ за промишлени и търговски сгради, наличен в стомана или алуминий.",
                ], 
                specs: [
                "Вълнообразен профил",
                "Подходящ за покриване на покриви или стени",
                "Стандартна стъпка 900 мм",
                ],   
                turn: 'seven'            
            },
            { productName: 'ISOVETRO', imgPath: image39Path, 
                desctiption: [
                "Сандвич панел Isovetro за покриви с полиуретанова изолация, с 5-ребрен гофриран профил и вътрешна опора от фибростъкло.",
                "Isovetro е сандвич панелна лампа, универсална и лесна за монтаж. Идеално решение за зоотехнически съоръжения.",
                ], 
                specs: [
                "Единичен панел",
                "Полиуретанова изолация",
                "5-рамка гофриран профил",
                "Вътрешна страна от фибростъкло",
                ],   
                turn: 'seven'             
            },
            { productName: 'ISOSMART', imgPath: image40Path, 
                desctiption: [
                "Isosmart сандвич панел за полиуретаново покритие, с 5-ребрен оребрен профил.",
                "Isosmart е панел за покриви, идеален за промишлени, търговски и селскостопански и зоотехнически съоръжения. Неговата полиуретанова изолация, неговата гъвкавост, товароносимост и лесен монтаж правят Isosmart добър избор за всеки тип проект, от ново строителство до ремонт на покрив.",
                ], 
                specs: [
                "Сандвич панел с двойна метална облицовка",
                "Полиуретанова изолация",
                "5-ребрен оребрен профил",
                ],   
                turn: 'seven'             
            },           
            { productName: 'ISORIGHE', imgPath: image41Path, 
                desctiption: [
                "Стенен полиуретанов панел Isorighe. С микрорайе завършване и видимо фиксиране. Може да се монтира вертикално или хоризонтално.",
                "Isorighe е сандвич панел, използван за стени на промишлени съоръжения, модули и вътрешни прегради. Фуга с видимо фиксиране.",
                ], 
                specs: [
                "Панел с двойно метално покритие и микроръбове",
                "Полиуретанова изолация",
                "Фуга с видимо фиксиране",
                "Може да се монтира вертикално или хоризонтално",
                ],    
                turn: 'seven'            
            },
            { productName: 'ISORAY', imgPath: image42Path, 
                desctiption: [
                "Полиуретанов извит сандвич панел Isoray с оребрен профил, предлага се с два различни вида извивки.",
                "Isoray е сандвич панел за индустриални покриви, изработен от извити панели от фиброцимент. Може да се изработи с радиус на огъване 3,3 m или 6 m.",
                ], 
                specs: [
                "Сандвич панел с двойна метална облицовка",
                "Полиуретанова изолация",
                "Може да се изработи с радиус на огъване 3,3 m или 6 m",
                ],   
                turn: 'seven'             
            },
            { productName: 'ISOGRECATA', imgPath: image43Path, 
                desctiption: [
                "Сандвич панел Isogrecata за полиуретаново покритие, с 5-ребрен оребрен профил и вътрешна опора от сентезимален алуминий.",
                "Isogrecata е сандвич панел, който е лек, универсален и лесен за монтаж. Това е идеалното решение за промишлени сгради, покриви на ново строителство или ремонт на покриви.",
                ], 
                specs: [
                "Единичен панел",
                "Полиуретанова изолация",
                "5-ребрен оребрен профил",
                "Вътрешна страна от релефен стотензимален алуминий",
                ],  
                turn: 'eight'              
            },            
            { productName: 'ISOCLASS', imgPath: image44Path, 
                desctiption: [
                "Полиуретанов стенен панел Isoclass, с гофрирано покритие и скрито закрепване.",
                "Isoclass е сандвич панел, използван за стени на промишлени и търговски сгради, с вълнообразен външен профил, характеризиращ се със скрита система за закрепване на фуги. Може да се използва в комбинация със стенните сандвич панели Isoparete Plissé, Isoparete Piano и Isoparete Box.",
                ], 
                specs: [
                "Панел с двойно метално покритие",
                "Полиуретанова изолация",
                "Фуга със скрито фиксиране",
                "Външен гофриран профил",
                "Възможност за комбиниране със съвместими панели"
                ],       
                turn: 'eight'         
            }    
        ],
        visibleCount: 6,
        selectedProduct: null,
        };
    },
    computed: {
    visibleProducts() {
      return this.products.slice(0, this.visibleCount);
    },
    showLoadMoreButton() {
      return this.visibleCount < this.products.length;
    },
  },
  methods: {
    loadMore() {
      this.visibleCount += 6;
    },
    showProductDetails(product) {
      this.selectedProduct = product;
      this.disableScroll();
      window.addEventListener('popstate', this.handlePopstate);
    },
    closeProductDetails() {
      this.selectedProduct = null;
      this.enableScroll();
      window.removeEventListener('popstate', this.handlePopstate);
    },
    disableScroll() {
      document.body.style.overflow = 'hidden';
    },
    enableScroll() {
      document.body.style.overflow = 'auto';
    },
    handlePopstate() {
      this.enableScroll();
    },
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handlePopstate);
  },
  mounted() {
    window.addEventListener('beforeunload', this.enableScroll);
  },
};
</script>
 
 
<style>
 
    .iso-products {
      width: 100%;
      background-color: #eae7e6;
      padding: 0 1em;
    }
 
    .products-header, .products-info {
        text-align: center;
        padding: 1em 0; 
    }
 
    .products-header {
        font-size: 5em;
        font-weight: bold;
        color: #49657b;
        outline-color: white;
        padding-bottom: 1em; 
        border-bottom: 3px solid #49657b;
        margin-bottom: 1em;
        margin-left: 3em;
        margin-right: 3em;
        text-align: center;
    }
 
 
    .iso-products-container{
        display: flex;
        justify-content: space-around;
        gap: 0.5em;
        margin: 2em 0;
        flex-wrap: wrap;
        margin: 0 15%;
    }
 
    .product-image{
        width: 100%;
        border: 2px solid #49657b;
        border-radius: 15px;
        height: 45%;
        object-fit: cover;
    }
 
    .iso-product{
        width: 30%;
        margin-bottom: 5em;
        margin-bottom: 4em;
        height: 100%
 
    }
 
    .product-name{
        padding: 1em;
        margin: 0 auto;
        width: 100%;
        font-size: 1.2em;
        color: #49657b;
        font-weight: bolder;
        border-bottom: 2px solid #49657b;
    }
 
    .product-desc{
        color:#404040 ;
        margin-top: 1em;
    }
 
    .view-more-btn{
        text-decoration: none;
        color: white;
        border-color: white;
        background-color: #476c8a;
        padding: 1em;
        border: 2px solid #f8fcf8;
        border-radius: 20px;
        width: 60%; 
        text-align: center;
        margin-top: 2em;
    }
 
    .view-more-btn:hover {
      border: solid 2px #476c8a;
      color: #476c8a;
      background-color: rgb(255, 255, 255, 0.5);
    }
 
    .load-more-btn{
        width: 100%;
        margin: 0 40%;
        padding: 2em 1em;
        border-radius: 20px;
        font-size: 1.1em;
        text-decoration: none;
        color: white;
        border-color: white;
        background-color: #476c8a;
        border: 2px solid #f8fcf8;
        border-radius: 20px;
        width: 60%; 
        text-align: center;
        margin-bottom: 3em;
 
    }
 
    .close-btn{
        background-color: rgba(0, 0, 0, 0);
        border: none;
        font-size: 2.5em;
        font-weight: 500; 
        position: absolute;
        top: 0.5em; 
        right: 1em; 
        color: lightgray;
        cursor: pointer;
 
    }
 
    .close-btn:hover{
        color: black;
    }
 
    .load-more-btn:hover{
        border: solid 2px #476c8a;
        color: #476c8a;
        background-color: rgb(255, 255, 255, 0.5);
    }
 
    .product-details{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        z-index: 1000;
        border-radius: 5px;
        width: 70%;
        height: 83%;
        box-shadow: 10px 10px 10px rgb(71, 108, 138, 0.55);
        border: 2px solid #49657b;
        overflow: scroll;
    }
 
    .product-more-image{
        width: 100%;
        height: 40%;
        object-fit: cover;
        border-radius: 5px;
        border-bottom: 3px solid #49657b;
    }
 
    .product-more-name{
        padding: 0.5em;
        width: 100%;
        font-size: 3em;
        color: #49657b;
        font-weight: bolder;
        border-bottom: 2px solid #49657b;
        margin: 0 auto;
        letter-spacing: 10px;
        text-align: center;
    }
 
    .bullet{
        font-size: 1.5em;
        color: #49657b;
    }
 
    .section-title{
        margin-bottom: 1em;
        font-size: 1.5em;
        color: #49657b;
    }
 
    .product-more-sec{
        display: flex;
    }
 
    .product-more-desc{
        margin-bottom: 0.5em;
    }
 
    .product-details-section{
        width: 50%;
        padding: 2em;
        font-size: 1em;
        cursor: pointer;
        padding-left: 4em;
    }
 
 
    @media(max-width: 1100px){
        .product-desc{
        display: none;
      }
 
    }
 
 
    @media(max-width: 620px){
        .products-header {
            margin: 0.5em 0;
        }
 
    }
 
 
    @media (max-width: 960px) {
 
      .iso-products{
        font-size: 60%;
      }
 
      .products-header-container{
        margin: 0 5em;
      }
 
      .iso-products-container{
        margin: 0 10%;
 
      }
 
      .iso-product{
        width: 40%;
      }
 
 
      .product-desc{
        display: none;
      }
 
      .view-more-btn{
        width: 100%;
        font-size: 0.8em;
      }
 
      .load-more-btn{
        margin: 0 20%;
        margin-bottom: 2em;
      }
 
      .product-details{
        left: 50%;
        width: 100%;
        height: 100%;
      }
 
      .product-more-sec{
        overflow: auto;
      }
 
 
    }
</style>